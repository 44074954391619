<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <!-- Loader -->
      <div
        v-if="isLoading"
        class="w-100 text-center"
      >
        <b-spinner label="Loading..." />
      </div>

      <!-- Error -->
      <div
        v-if="isError"
        class="w-100 text-center"
      >
        <b-card class="mb-0">
          <div class="text-center m-2">
            <feather-icon
              size="50"
              style="color: #ea5455"
              icon="XCircleIcon"
            />
          </div>
          <div class="text-center">
            <p>There was a problem verifying your email.</p>
            <p>Please try again or contact our support.</p>
            <b-button
              variant="primary"
              class="m-1 btn-sm-block"
              @click="handleRefreshClick"
            >
              Refresh
            </b-button>
          </div>
        </b-card>
      </div>

      <!-- Verified -->
      <div
        v-if="isVerified"
        class="w-100 text-center"
      >
        <b-card class="mb-0">
          <b-card-title class="mb-4 text-left">
            Hey champ 👋
          </b-card-title>

          <b-card-title class="mb-4 text-left">
            🏆 Your account is verified!
          </b-card-title>

          <b-card-title class="mb-4 text-left">
            Before you start we recommend these steps:
          </b-card-title>

          <b-card-text class="pl-2">
            <ul class="pl-2">
              <li class="mb-3 text-left">
                Update your profile with profile picture
              </li>
              <li class="mb-3 text-left">
                Join a team or create your first team
              </li>
              <li class="mb-3 text-left">
                Update your teams page information
              </li>
              <li class="mb-3 text-left">
                Connect your account to Steam, Epic games, RIOT...
              </li>
              <li class="mb-3 text-left">
                Find free tournaments, join and compete
              </li>
              <li class="mb-3 text-left">
                Compete in tournaments with prizes
              </li>
            </ul>
          </b-card-text>

          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{ name: 'login' }"
          >
            Login to start
          </b-button>

          <div class="divider my-2">
            <div class="divider-text">
              or if you forgot password
            </div>
          </div>
          <b-link :to="{ name: 'auth-reset-password' }">
            <feather-icon icon="ChevronLeftIcon" />
            Reset Password
          </b-link>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BLink,
  BSpinner,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BLink,
    BSpinner,
    FeatherIcon,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      downImg: require('@/assets/images/pages/error-dark.svg'),
      isVerified: false,
      isLoading: true,
      isError: false,
    }
  },
  computed: {
    imgUrl() {
      return this.downImg
    },
  },

  async mounted() {
    await this.verify()
  },

  methods: {
    handleRefreshClick() {
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    },
    // eslint-disable-next-line no-empty-function
    async verify() {
      const userId = this.$route.query.id
      const { hash } = this.$route.query

      this.isLoading = true
      const { success } = this.$api.player.verifyEmail(
        userId,
        hash,
      )

      this.isLoading = false

      if (success === false) {
        this.isError = true
      } else {
        this.isVerified = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/page-misc.scss';
@import '~@core/scss/base/bootstrap-extended/variables.scss';
@import '~@core/scss/base/components/divider.scss';

</style>
