var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "misc-wrapper"
  }, [_c('div', {
    staticClass: "misc-inner p-2 p-sm-3"
  }, [_vm.isLoading ? _c('div', {
    staticClass: "w-100 text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), _vm.isError ? _c('div', {
    staticClass: "w-100 text-center"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('div', {
    staticClass: "text-center m-2"
  }, [_c('feather-icon', {
    staticStyle: {
      "color": "#ea5455"
    },
    attrs: {
      "size": "50",
      "icon": "XCircleIcon"
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('p', [_vm._v("There was a problem verifying your email.")]), _c('p', [_vm._v("Please try again or contact our support.")]), _c('b-button', {
    staticClass: "m-1 btn-sm-block",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handleRefreshClick
    }
  }, [_vm._v(" Refresh ")])], 1)])], 1) : _vm._e(), _vm.isVerified ? _c('div', {
    staticClass: "w-100 text-center"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-card-title', {
    staticClass: "mb-4 text-left"
  }, [_vm._v(" Hey champ 👋 ")]), _c('b-card-title', {
    staticClass: "mb-4 text-left"
  }, [_vm._v(" 🏆 Your account is verified! ")]), _c('b-card-title', {
    staticClass: "mb-4 text-left"
  }, [_vm._v(" Before you start we recommend these steps: ")]), _c('b-card-text', {
    staticClass: "pl-2"
  }, [_c('ul', {
    staticClass: "pl-2"
  }, [_c('li', {
    staticClass: "mb-3 text-left"
  }, [_vm._v(" Update your profile with profile picture ")]), _c('li', {
    staticClass: "mb-3 text-left"
  }, [_vm._v(" Join a team or create your first team ")]), _c('li', {
    staticClass: "mb-3 text-left"
  }, [_vm._v(" Update your teams page information ")]), _c('li', {
    staticClass: "mb-3 text-left"
  }, [_vm._v(" Connect your account to Steam, Epic games, RIOT... ")]), _c('li', {
    staticClass: "mb-3 text-left"
  }, [_vm._v(" Find free tournaments, join and compete ")]), _c('li', {
    staticClass: "mb-3 text-left"
  }, [_vm._v(" Compete in tournaments with prizes ")])])]), _c('b-button', {
    staticClass: "mb-2 btn-sm-block",
    attrs: {
      "variant": "primary",
      "to": {
        name: 'login'
      }
    }
  }, [_vm._v(" Login to start ")]), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_vm._v(" or if you forgot password ")])]), _c('b-link', {
    attrs: {
      "to": {
        name: 'auth-reset-password'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Reset Password ")], 1)], 1)], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }